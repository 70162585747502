import androidx.compose.foundation.layout.fillMaxWidth
import androidx.compose.foundation.layout.height
import androidx.compose.runtime.remember
import androidx.compose.ui.Modifier
import androidx.compose.ui.unit.dp
import androidx.compose.ui.unit.max
import com.jfayz.appointments.App
import com.kirillNay.telegram.miniapp.compose.telegramWebApp
import com.kirillNay.telegram.miniapp.webApp.webApp

fun main() {
    telegramWebApp { style ->
        val height = remember(style.viewPort.viewPortHeight) {
            max(style.viewPort.viewPortHeight, 500.dp)
        }

        val text1 = "data: ${webApp.rawInitData}"
        val text2 = webApp.initDataUnsafe.toString()

        App(
            modifier = Modifier.height(height).fillMaxWidth(),
            text1 = text1,
            text2 = text2,
        )

        println(text1)
        println(text2)
    }


//    onWasmReady {
//        val body = document.body ?: return@onWasmReady
//        ComposeViewport(body) {
//            App()
//        }
//    }
}
